import { faSyncAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlLabel, FormGroup, Grid, Switch } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import LocalizedStrings from 'react-localization';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { ClearDialog } from "../components/dialog/ClearDialog";
import { NewOrContinueDialog } from "../components/dialog/NewOrContinueDialog";
import { NavigationButton } from '../components/NavigationButton';
import { Routes } from '../model/routes';
import { HomeDto } from '../model/typegen/homeDto';
import { RootState } from '../redux';
import { getQuestionsByClassifications, QuestionTypeState } from "../redux/modules/question";
import { setNarration } from "../redux/modules/user";
import { theme } from '../theme';

const styles = (theme: Theme) =>
    createStyles({
        root: {

        },
        headerText: {
            position: 'absolute',
            zIndex: 1,
            color: 'white',
            textAlign: 'center',
            width: '100%',
            top: '25%',
            fontFamily: 'Overpass Bold',
        },
        logo: {
            width: '7.5rem',
            position: 'absolute',
            zIndex: 1,
            marginTop: '0.8rem',
            left: '50%',
            transform: 'translate(-50%)'
        },
        contentContainer: {
        },
        gridContainer: {
            position: 'absolute',
            top: '55%',
            marginLeft: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxHeight: '70%',
            overflow: 'auto',
            zIndex: 2,
            backgroundColor: 'white'
        },
        navigationContainer: {
            marginTop: '1rem',
            width: '80%',
            margin: 'auto'
        },
        bottomRightTriangle: {
            position: 'absolute',
            bottom: '2rem',
            right: '0rem',
            borderLeft: '4rem solid transparent',
            borderBottom: '2.8rem solid ' + theme.palette.primary.light,
        },
        footer: {
            position: 'absolute',
            bottom: 0,
            height: '2rem',
            backgroundColor: theme.palette.primary.light,
            width: '100%'
        },
        rootWaveContainer: {
            display: 'inline-block',
            position: 'relative',
            width: '100%',
            paddingBottom: '12rem',
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
        waveBackground: {
            width: '100%'
        },
        waveContainer1: {
            position: 'absolute',
            width: '100%',
            top: 0,
            zIndex: 1
        },
        waveContainer2: {
            position: 'absolute',
            top: 0,
            width: '100%'
        },
        wave1: {
            maxHeight: 120,
            width: '100%'
        },
        wave2: {
            maxHeight: 150,
            width: '100%'
        },
        refresh: {
            position: 'fixed',
            top: '1rem',
            right: '1rem',
            color: 'white',
            fontSize: '1.4rem',
            cursor: "pointer"
        }
    });

const strings = new LocalizedStrings({
    svSE: {
        appName: "Vägmärkeskollen",
        signs: "Märken",
        assertions: "Påståenden",
        mixed: "Blandat",
        categories: "Kategorier",
        roadSignList: "Vägmärkesförteckning",
        narration: "Uppläsning",
        language: "Språk"
    },
    enUS: {
        appName: "Vägmärkeskollen Eng",
        signs: "Signs",
        assertions: "Assertions",
        mixed: "Mixed",
        categories: "Categories",
        roadSignList: "Road sign list",
        narration: "Narration",
        language: "Language"
    }
});

const mapStateToProps = (state: RootState) => ({
    signQuestions: state.questions.signQuestions,
    assertionQuestions: state.questions.assertionQuestions,
    mixedQuestions: state.questions.mixedQuestions,
    categoryQuestions: state.questions.categoryQuestions,
    narration: state.user.narration
});

const mapDispatchToProps = { setNarration, getQuestionsByClassifications };

interface IProps extends RouteComponentProps {

}

interface IState {
    language: string
    home?: HomeDto
    showNewOrContinueDialog: boolean,
    showClearDialog: boolean,
    currentQuestionTypeState?: QuestionTypeState
}

type IPropsWithStyle = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IProps & WithStyles<typeof styles>;

class Home extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            language: 'svSE',
            home: undefined,
            showNewOrContinueDialog: false,
            currentQuestionTypeState: undefined,
            showClearDialog: false
        }
    }

    async componentDidMount() {
        // let homeDto = (await getHomeDto()).data;
        // this.setState({
        //     home: homeDto
        // });
    }

    componentDidUpdate(prevProps: IPropsWithStyle) {
        if (this.props.signQuestions !== prevProps.signQuestions ||
            this.props.assertionQuestions !== prevProps.assertionQuestions ||
            this.props.mixedQuestions !== prevProps.mixedQuestions) {
            this.props.history.push(this.state.currentQuestionTypeState?.questionTypeRoutes.test as string);
        }
    }

    handleLanguageChange = (e: React.ChangeEvent<{ value: any }>) => {
        e.preventDefault();
        let lang = e.target.value;
        this.setState(prevState => ({
            language: lang
        }))
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setNarration(event.target.checked);
    };

    showDialogClick = (questions: QuestionTypeState) => {
        //Only show dialog if a user has previously answered questions
        if (questions.questionResponses.length > 0) {
            this.setState(() => ({
                showNewOrContinueDialog: true,
                currentQuestionTypeState: questions
            }));
        }
        else {
            //Handle category choices
            if (questions.questionTypeRoutes.test === Routes.CategoriesTest) {
                this.props.history.push(Routes.Categories);
            }
            else {
                this.props.history.push(questions.questionTypeRoutes.test);
            }
        }
    };

    closeDialogClick = () => {
        this.setState(() => ({
            showNewOrContinueDialog: false
        }));
    };

    resumeTestClick = () => {
        this.props.history.push(this.state.currentQuestionTypeState?.questionTypeRoutes.test as string);
    };

    newTestClick = () => {
        //Handle category choices
        if (this.state.currentQuestionTypeState?.questionTypeRoutes.test === Routes.CategoriesTest) {
            this.props.history.push(Routes.Categories);
        }
        else {
            this.props.getQuestionsByClassifications(this.state.currentQuestionTypeState as QuestionTypeState, this.state.currentQuestionTypeState?.classifications as string[]);
        }
    };


    closeClearDialogClick = () => {
        this.setState(() => ({
            showClearDialog: false
        }));
    }

    openClearDialogClick = () => {
        this.setState(() => ({
            showClearDialog: true
        }));
    }

    confirmClearDialogClick = async () => {
        localStorage.removeItem('localStorage');
        window.location.reload();
    }




    public render() {
        const { classes } = this.props;
        strings.setLanguage(this.state.language);

        return (
            <div className={classes.root}>
                <NewOrContinueDialog
                    open={this.state.showNewOrContinueDialog}
                    handleClose={this.closeDialogClick}
                    handleResumeTest={this.resumeTestClick}
                    handleNewTest={this.newTestClick} />
                <ClearDialog open={this.state.showClearDialog} handleClose={this.closeClearDialogClick} handleConfirm={this.confirmClearDialogClick} />
                <div className={classes.rootWaveContainer}>
                    <div className={classes.waveContainer1}>
                        <img className={classes.logo} src="str_logo_large.png" alt="" />
                        <div className={classes.refresh} onClick={() => this.openClearDialogClick()}>
                            <FontAwesomeIcon icon={faSyncAlt} />
                        </div>
                        <div className={classes.headerText}>
                            <h1>{strings.appName}</h1>
                        </div>
                        <div className={classes.waveBackground}>
                            <svg viewBox="0 0 375 436" className={classes.wave1} fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" max-height="436">
                                <path d="M355.5 357.256C206.467 324.762 100.784 434.353 0.00633657 435.397C-100.771 436.44 -31.3228 206.288 -31.9045 25.4476C-32.4862 -155.393 297.749 -32.8359 398.526 -33.8797C398.526 133.642 518.141 392.716 355.5 357.256Z" fill={theme.palette.primary.main} />
                            </svg>
                        </div>
                    </div>
                    <div className={classes.waveContainer2}>
                        <div className={classes.waveBackground}>
                            <svg viewBox="0 0 375 481" className={classes.wave2} fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
                                <path d="M349.5 398.504C200.467 366.011 107.224 479.393 6.44677 480.437C-94.3308 481.48 -24.8824 251.328 -25.4641 70.4877C-26.0458 -110.353 304.189 12.2041 404.967 11.1603C404.967 178.682 512.141 433.964 349.5 398.504Z" fill={theme.palette.primary.light} />
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={classes.contentContainer}>
                    <Grid container spacing={0} className={classes.gridContainer}>
                        <Grid item xs={12}>
                            <div className={classes.navigationContainer}>
                                <NavigationButton icon="fal fa-do-not-enter fa-lg" text={strings.signs}
                                    handleClick={() => this.showDialogClick(this.props.signQuestions)} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.navigationContainer}>
                                <NavigationButton icon="fal fa-comment-alt-lines fa-lg" text={strings.assertions}
                                    handleClick={() => this.showDialogClick(this.props.assertionQuestions)} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.navigationContainer}>
                                <NavigationButton icon="fal fa-random fa-lg" text={strings.mixed}
                                    handleClick={() => this.showDialogClick(this.props.mixedQuestions)} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.navigationContainer}>
                                <NavigationButton icon="fal fa-ballot-check fa-lg" text={strings.categories}
                                    handleClick={() => this.showDialogClick(this.props.categoryQuestions)} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.navigationContainer}>
                                <NavigationButton icon="fal fa-books fa-lg" text={strings.roadSignList}
                                    handleClick={() => { this.props.history.push(Routes.RoadSignList); }} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {/*
                             <FormControl component="fieldset">
                             */}
                            <div className={classes.navigationContainer}>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={this.props.narration}
                                                onChange={this.handleChange}
                                                name="narration"
                                                color="primary"
                                            />
                                        }
                                        label={strings.narration}
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </div>
                        </Grid>
                        {/*
                        <Grid item xs={12}>
                            <FormControl variant="outlined">
                                <InputLabel id="language-select-label">{strings.language}</InputLabel>
                                <Select
                                    labelId="language-select-label"
                                    id="language-select"
                                    value={this.state.language}
                                    onChange={this.handleLanguageChange}
                                    label={strings.language}
                                >
                                    <MenuItem value={"svSE"}>Svenska</MenuItem>
                                    <MenuItem value={"enUS"}>English</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        */}
                    </Grid>
                    <div className={classes.bottomRightTriangle}>
                    </div>
                </div>
                <footer className={classes.footer}>
                </footer>
            </div>
        );
    }
}

export default withRouter(compose<React.ComponentType<IProps>>(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Home))