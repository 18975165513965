
export function ArrayToURL(array: string[], queryParameterName: string) {
    var pairs = [];
    for (var value in array) {
        pairs.push(encodeURIComponent(queryParameterName) + '=' + encodeURIComponent(array[value]));
    }
    return pairs.join('&');
};

export function DictionaryObjectToURL(object: any, queryParameterName: string) {
    var pairs = [];
    let i = 0;
    for (var property in object) {
        pairs.push(`${encodeURIComponent(queryParameterName)}[${i}].key=${property}&${encodeURIComponent(queryParameterName)}[${i}].value=${object[property]}`);
        i++;
    }
    return pairs.join('&');
};

