import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from './AbortDialog';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleResumeTest: () => void;
    handleNewTest: () => void;
}

export const NewOrContinueDialog: React.SFC<IProps> = (props) => {

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Återuppta eller avbryt test?
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Du har ett pågående test. Vill du återuppta testet eller starta ett nytt?
                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleNewTest} color="secondary">
                    Nytt test
                 </Button>
                <Button onClick={props.handleResumeTest} color="primary">
                    Återuppta test
                 </Button>
            </DialogActions>
        </Dialog>
    );
}