import { Button, CircularProgress, Grid } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { AbortDialog } from '../components/dialog/AbortDialog';
import { StatisticsBox } from '../components/StatisticsBox';
import { TopBar } from '../components/TopBar';
import { Routes } from '../model/routes';
import { RootState } from '../redux';
import { ChoiceQuestionResponse, QuestionTypeState } from '../redux/modules/question';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        headerMargin: {
            margin: 0
        },
        result: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            paddingBottom: '1rem'
        },
        successCircularProgress: {
            color: theme.palette.success.light,
            position: 'absolute',
            zIndex: 1
        },
        errorCircularProgress: {
            color: theme.palette.error.light
        },
        statisticsContainer: {
            paddingTop: '0.5rem',
            display: 'flex',
            justifyContent: 'center',
            height: '55vh',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                height: '55vw'
            },
            overflow: 'auto'
        },
        statisticsContent: {
            paddingBottom: '1rem',
        },
        statisticsItemRight: {
            paddingLeft: '0.25rem',
        },
        reviewButton: {
            width: '100%',
            paddingRight: '0.5rem'
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: 'auto'
        },
        itemContent: {
            height: '11vh',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                height: '11vw'
            },
            padding: '0.5rem',
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '0.5rem',
        },
    });

const mapStateToProps = (state: RootState) => ({

});

interface IProps extends RouteComponentProps {
    questions: QuestionTypeState
}

interface IState {
    showAbortDialog: boolean,
    questionResponses: ChoiceQuestionResponse[];
    correctResults: number;
    correctResultsPercent: number;
    categoryStatistics: IStatistics[]
}

interface IStatistics {
    category: string,
    percent: number
}

type IPropsWithStyle = IProps & ReturnType<typeof mapStateToProps> & WithStyles<typeof styles>;

class Result extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            showAbortDialog: false,
            questionResponses: [],
            correctResults: 0,
            correctResultsPercent: 0,
            categoryStatistics: []
        }
    }

    componentDidMount() {
        this.setQuestionData(this.props.questions);
    }

    setQuestionData = (questionTypeState: QuestionTypeState) => {
        let questionResponses = questionTypeState.questionResponses;
        let questionIds = questionTypeState.questionIds;
        let correctResults = 0;
        let categoryStatistics: IStatistics[] = []
        questionResponses = _.cloneDeep(questionResponses);
        if (questionResponses.length !== questionIds.length) {
            this.props.history.push(this.props.questions.questionTypeRoutes.test);
        }

        let groupedQuestionResponses1 = _.groupBy(questionResponses, "choiceQuestion.roadSignClassificationDescription")

        let groupedQuestionResponsesArrays = _.toArray(groupedQuestionResponses1);

        for (let questionResponseGroup of groupedQuestionResponsesArrays) {
            let statistics: IStatistics = {
                category: '',
                percent: 0
            };
            let statisticsResult = 0;
            statistics.category = questionResponseGroup[0].choiceQuestion.roadSignClassificationDescription;
            for (let questionResponse of questionResponseGroup) {
                if (questionResponse.responseOptionId === questionResponse.correctResponseOptionId) {
                    statisticsResult++
                    correctResults++;
                }
            }
            statistics.percent = this.calculatePercent(statisticsResult, questionResponseGroup.length);
            categoryStatistics.push(statistics);
        }

        //GUI only handles four or above categories
        if (categoryStatistics.length >= 4) {
            let sortedArray = categoryStatistics.sort((a, b) => { return a.percent - b.percent });
            let max1 = sortedArray[sortedArray.length - 1];
            let max2 = sortedArray[sortedArray.length - 2];
            let min1 = sortedArray[0];
            let min2 = sortedArray[1];
            categoryStatistics = [];
            categoryStatistics.push(max1, max2, min1, min2)
        }
        else {
            categoryStatistics = [];
        }

        let percent = this.calculatePercent(correctResults, questionResponses.length);

        this.setState(prevState => ({
            questionResponses: questionResponses,
            correctResults: correctResults,
            correctResultsPercent: percent,
            categoryStatistics: categoryStatistics
        }))
    };

    calculatePercent = (result: number, total: number): number => {
        if (result === 0 && total === 0) {
            return 0;
        }

        let calculatedPercentage = (result / total) * 100
        let formattedPercentage = new Intl.NumberFormat("sv-SV", {
            style: "decimal",
            maximumFractionDigits: 0
        }).format(calculatedPercentage)

        return Number(formattedPercentage);
    };

    showDialogClick = () => {
        this.setState(prevState => ({
            showAbortDialog: true
        }))
    }

    closeDialogClick = () => {
        this.setState(prevState => ({
            showAbortDialog: false
        }))
    }

    confirmDialogClick = () => {
        this.props.history.push(Routes.Home);
    }

    navigateToResultReview = () => {
        this.props.history.push(this.props.questions.questionTypeRoutes.resultReview);
    }

    public render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <TopBar handleClose={() => this.props.history.push(Routes.Home)} />
                <AbortDialog open={this.state.showAbortDialog} handleClose={this.closeDialogClick} handleConfirm={this.confirmDialogClick} />
                <div className={classes.result}>
                    <h1 className={classes.headerMargin}>Resultat</h1>
                    <h1 className={classes.headerMargin}>{this.state.correctResults}/{this.state.questionResponses.length}</h1>
                    <div>
                        <CircularProgress variant="static" size="8rem" thickness={8} value={this.state.correctResultsPercent} className={classes.successCircularProgress} />
                        <CircularProgress variant="static" size="8rem" thickness={8} value={100} className={classes.errorCircularProgress} />
                    </div>
                </div>

                <Grid container className={classes.statisticsContainer}>
                    <Grid item xs={10} sm={8} md={4}>
                        {this.state.categoryStatistics.length >= 4 ?
                            <>
                                <div className={classes.statisticsContent}>
                                    Bäst resultat
                                    <Grid container >
                                        <Grid item xs={6}>
                                            <div className={classes.itemContent}>
                                                <StatisticsBox category={this.state.categoryStatistics[0].category}
                                                    percent={this.state.categoryStatistics[0].percent} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} className={classes.statisticsItemRight}>
                                            <div className={classes.itemContent}>
                                                <StatisticsBox category={this.state.categoryStatistics[1].category}
                                                    percent={this.state.categoryStatistics[1].percent} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={classes.statisticsContent}>
                                    Sämst resultat
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <div className={classes.itemContent}>
                                                <StatisticsBox category={this.state.categoryStatistics[2].category}
                                                    percent={this.state.categoryStatistics[2].percent} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} className={classes.statisticsItemRight}>
                                            <div className={classes.itemContent}>
                                                <StatisticsBox category={this.state.categoryStatistics[3].category}
                                                    percent={this.state.categoryStatistics[3].percent} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                            : <></>}

                        <Button variant="contained" color="secondary" className={classes.reviewButton} onClick={this.navigateToResultReview}>
                            <b>Granska</b>
                        </Button>
                    </Grid>
                </Grid>
                <footer className={classes.footer}>
                </footer>
            </div >
        );
    }
}

export default withRouter(compose<React.ComponentType<IProps>>(withStyles(styles), connect(mapStateToProps))(Result))