import { Grid } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { TopBar } from '../components/TopBar';
import { Routes } from '../model/routes';

interface IProps extends RouteComponentProps {
}

interface IState {
}

type IPropsWithStyle = IProps & WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
        },
        content: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        centerContainer: {
            justifyContent: 'center'
        },
    });

class CookiePolicy extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
        }
    }

    cancelClick = () => {
        this.props.history.push(Routes.Home);
    }

    public render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.cancelClick} />
                <div className={classes.content}>
                    <Grid container item xs={12} sm={10} md={5} className={classes.centerContainer}>
                        <Grid item xs={10} >
                            <h1 style={{textAlign: 'center'}}>Cookiepolicy</h1>
                            <p>På denna sida hittar du information om hur vi använder cookies.
                            Här nedanför kan du läsa mer om vad en cookie är, vilka cookies vi använder,
                            varför vi använder cookies och hur du kan blockera eller radera cookies.
                        </p>
                            <h3>Vad är en cookie?</h3>

                            <p>
                                En "cookie" är en textfil som innehåller information som lagras på din dator.
                                Cookies används av tekniska skäl för att underlätta ditt användande av hemsidan och för att vi ska kunna
                                förbättra hemsidans funktioner. En typ av cookie är "permanenta cookies".
                                Dessa sparar en fil på din dator som finns kvar under en längre tid.
                                Denna fil kan då användas för att anpassa hemsidan baserat på dina val och preferenser.
                                En annan vanlig typ av cookie är ”session cookies”. Session cookies skickas mellan din dator och en
                                server för att samla in information vid ditt besök på hemsidan. Till skillnad från permanenta cookies
                                sparas inte session cookies efter att du har stängt ner din webbläsare. Utöver session och permanenta
                                cookies finns det också tredjepartscookies som är cookies som tillhör andra domäner än de som visas i
                                adressfältet. Tredjepartscookies öppnar upp möjligheten att spåra användarens webbhistorik. Besök
                                gärna <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a> för mer information om hur cookies fungerar.
                                </p>
                            <h3>Följande cookies används:</h3>
                            <p>
                                ARRAffinity är en teknisk kaka som sätts av Microsoft Azure och är nödvändig för att applikationen ska fungera.
                            </p>

                            <h3>Hur kan du stänga av användningen av cookies?</h3>
                            <p>
                                Om du inte accepterar att cookies används kan du ändra din webbläsares inställningar till att
                                antingen neka lagringen av cookies eller till att du informeras om när en hemsida vill lagra
                                cookies på din dator. Cookies som lagrats på datorn vid ett tidigare tillfälle kan också raderas
                                genom webbläsaren (vänligen se mer information om detta nedan). Det finns också en webbplats du
                                kan besöka som listar många av de företag som erbjuder beteendebaserad reklam och där du kan
                                förbjuda dem att samla cookies om dig. Det betyder dock inte att du kommer slippa reklam när du
                                surfar. Adressen dit är  <a href="https://www.youronlinechoices.com">www.youronlinechoices.com</a>.
                            </p>
                            <h3>Hur kan du radera cookies som är sparade sedan tidigare?</h3>
                            <p>
                                Om du använder en PC och en uppdaterad version av din webbläsare och önskar radera cookies
                                som är sparade sedan tidigare kan du trycka på CTRL, SHIFT och DELETE samtidigt. Se följande
                                länkar för mer information om användningen av olika webbläsare:
                                <ul>
                                    <li>
                                        <a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">Internet Explorer</a>
                                    </li>
                                    <li>
                                        <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">Mozilla Firefox</a>
                                    </li>
                                    <li>
                                        <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">Google Chrome</a>           
                                    </li>
                                    <li>
                                        <a href="https://support.apple.com/en-us/HT201265">Safari</a>
                                    </li>
                                    <li>
                                        <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html">Flash cookies</a>
                                    </li>
                                </ul>
                            </p>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles)
)(CookiePolicy));