import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            width: '100%',
            height: 'inherit',
            display: 'flex',
            alignItems: 'center',
            paddingTop: '0.3rem'
        },
        headerText: {
            margin: '0.2rem 0 0.5rem 0',
            textOverflow: 'ellipsis',
            maxWidth: '33vw',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
        content: {
            justifyContent: 'center',
            alignItems: 'center',
            width: 'inherit'
        }
    }),
);

interface IProps {
    percent: number;
    category: string
}

export const StatisticsBox: React.SFC<IProps> = (props) => {

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <div className={classes.content}>
                    <h2 style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>{props.percent} %</h2>
                    <div >
                        <small>Rätta svar i kategorin</small>
                    </div>
                    <h4 className={classes.headerText}>
                        {props.category}
                    </h4>
                </div>
            </div>
        </>
    );
}