import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from './AbortDialog';

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
}

export const ClearDialog: React.SFC<IProps> = (props) => {

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Vill du nollställa allt?
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Om du fortsätter kommer du att nollställa alla test.
                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleConfirm} color="primary">
                    Fortsätt
                 </Button>
            </DialogActions>
        </Dialog>
    );
}