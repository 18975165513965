import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '0.5rem',
            height: '3rem',
            display: 'flex',
            alignItems: 'center',
            color: theme.palette.secondary.main,
            cursor: "pointer"
        },
        iconContainer: {
            alignItems: 'center',
            marginRight: '1.5rem',
            paddingLeft: '0.7rem',
            flexGrow: 0,
            maxWidth: '1.5rem',
            flexBasis: '1.5rem',
        },
    }),
);

interface IProps {
    icon: string;
    text: string;
    handleClick: () => void;
}

export const NavigationButton: React.SFC<IProps> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root} onClick={props.handleClick}>
                <span className={classes.iconContainer}>
                    <i className={props.icon}></i>
                </span>
                <span>
                    {props.text}
                </span>
        </div>
    );
}