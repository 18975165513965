import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {

        },
        logo: {
            width: '7.5rem',
            marginTop: '0.8rem',
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block'
        },
        squareButton: {
            width: '3.6rem',
            height: '3.6rem',
            backgroundColor: theme.palette.primary.main,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            WebkitClipPath: 'polygon(100% 0, 100% 79%, 70% 100%, 0 100%, 0 0)',
            clipPath: 'polygon(100% 0, 100% 79%, 70% 100%, 0 100%, 0 0)',
            cursor: "pointer"
        }
    }),
);

interface IProps {
    handleClose: () => void;
    icon?: JSX.Element;
}

export const TopBar: React.SFC<IProps> = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={3}>
                    <div className={classes.squareButton} style={{ textDecoration: 'none' }} onClick={props.handleClose}>
                        {!props.icon ? <i className="fal fa-times fa-3x"></i> : props.icon}
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <img className={classes.logo} src="/str_logo_large.png" alt="" />
                </Grid>
                <Grid item xs={3}>
                </Grid>
            </Grid>
        </div>
    );
}