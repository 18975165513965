import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { RootState } from '../redux';
import { setAcceptCookiePolicy } from "../redux/modules/question";
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { compose } from 'redux';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            //'& > *': {
            //    backgroundColor: theme.palette.primary.light,
            //}
            height: 'inherit'
        },
    }),
);

const mapStateToProps = (state: RootState) => ({
    acceptCookiePolicy: state.questions.acceptCookiePolicy,
});

const mapDispatchToProps = {
    setAcceptCookiePolicy,
};

interface IProps extends RouteComponentProps{

}

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & IProps;

const UnconnectedShared: React.FC<Props> = (props) => {
    const classes = useStyles();

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        props.setAcceptCookiePolicy(true);
    };

    return (
        <div className={classes.root}>
            {props.children}
            <Snackbar open={!props.acceptCookiePolicy} autoHideDuration={null} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info">
                    Vi använder cookies för att ge dig en bättre upplevelse av webbplatsen. 
                    Om du fortsätter innebär det att du accepterar att cookies används enligt vår <Link to="/cookie-policy">cookie-policy</Link>.
                </Alert>
            </Snackbar>
        </div>
    );
}

export const Shared = withRouter(compose<React.ComponentType<IProps>>(
    connect(mapStateToProps, mapDispatchToProps),
)(UnconnectedShared))