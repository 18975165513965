import { Badge } from '@material-ui/core';
import { createStyles, fade, makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import { OptionDto } from '../../model/typegen/optionDto';

const StyledSuccessBadge = withStyles((theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        badge: {
            height: '23px',
            minWidth: '23px',
            borderRadius: '11.5px'
        },
        colorPrimary: {
            backgroundColor: theme.palette.success.light,
            color: 'white'
        }
    }),
)(Badge);

const StyledErrorBadge = withStyles((theme) =>
    createStyles({
        root: {
            width: '100%'
        },
        badge: {
            height: '23px',
            minWidth: '23px',
            borderRadius: '11.5px',
            fontSize: '0.85rem'
        },
        colorPrimary: {
            backgroundColor: theme.palette.error.light,
            color: 'white'
        }
    }),
)(Badge);

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '0.5rem',
            minHeight: '2rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0.5rem',
            width: '100%',
            textAlign: 'center'
        },
        border: {
            borderStyle: 'solid',
            borderWidth: '2px',
            margin: '-2px',
        },
        correctResponse: {
            borderColor: theme.palette.success.light,
            backgroundColor: fade(theme.palette.success.light, 0.4)
        },
        wrongResponse: {
            borderColor: theme.palette.error.light,
            backgroundColor: fade(theme.palette.error.light, 0.4)
        }
    }),
);

interface IProps {
    optionDto: OptionDto;
    correctOptionId?: number
    clickedOptionId?: number
    onClick: (optionId: number) => void
}

export const SignQuestionButton: React.SFC<IProps> = (props) => {
    const classes = useStyles();

    function isCorrectSelected() {
        return props.clickedOptionId === props.optionDto.optionId
            && props.correctOptionId ? props.correctOptionId === props.optionDto.optionId : false;
    }

    function isIncorrectSelected() {
        return props.clickedOptionId === props.optionDto.optionId
            && props.correctOptionId ? props.correctOptionId !== props.clickedOptionId : false;
    }


    return (
        <>
            <StyledSuccessBadge badgeContent={<i className="fas fa-check"></i>} color="primary" invisible={props.clickedOptionId === undefined || props.correctOptionId !== props.optionDto.optionId} anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
                <StyledErrorBadge badgeContent={<i className="fa fa-times"></i>} color="primary" invisible={props.clickedOptionId === undefined || props.correctOptionId === props.optionDto.optionId} anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                    <div className={`${classes.root} 
                         ${props.clickedOptionId === props.optionDto.optionId ? classes.border : ''}                         
                         ${isCorrectSelected() ? classes.correctResponse : ''}
                         ${isIncorrectSelected() ? classes.wrongResponse : ''}`}
                        style={props.correctOptionId ? {} : { cursor: 'pointer' }}
                        onClick={() => { props.onClick(props.optionDto.optionId) }}>
                        {props.optionDto.text}
                    </div>
                </StyledErrorBadge>
            </StyledSuccessBadge>

        </>
    );
}