import { AxiosPromise } from 'axios';
import { ChoiceQuestionDto } from '../model/typegen/choiceQuestionDto';
import { InitialLoadDto } from '../model/typegen/initialLoadDto';
import { sendRequest } from './request';
import { ArrayToURL } from "./apiHelpers";

const path = '/api/question/'

export const getChoiceQuestionList = (number: number, classifications: string[]): AxiosPromise<Array<number>> => {
    return sendRequest(path + 'GetChoiceQuestionList?number=' + number + '&' + ArrayToURL(classifications, 'classifications'), 'get');
}

export const initialLoad = (number: number): AxiosPromise<InitialLoadDto> => {
    return sendRequest(path + 'InitialLoad/' + number, 'get');
}

export const getChoiceQuestion = (id: number): AxiosPromise<ChoiceQuestionDto> => {
    return sendRequest(path + id, 'get');
}

export const getChoiceQuestionByIdentifier = (identifier: string): AxiosPromise<ChoiceQuestionDto> => {
    return sendRequest(path + '?identifier=' + identifier, 'get');
}

//Returns correct optionId for question
export const correctChoiceQuestion = (id: number): AxiosPromise<number> => {
    return sendRequest(path + 'CorrectQuestion/' + id, 'get');
}
