import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { Grid } from '@material-ui/core';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
        },
        closeButton: {
            right: theme.spacing(1),
            bottom: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

export const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Grid container >
                <Grid item xs={11}>
                    <Typography variant="h6">{children}</Typography>
                </Grid>
                <Grid item xs={1}>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                    ) : null}
                </Grid>
            </Grid>
        </MuiDialogTitle>
    );
});

export const DialogContent = withStyles((theme: Theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export const DialogActions = withStyles((theme: Theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

interface IProps {
    open: boolean;
    handleClose: () => void;
    handleConfirm: () => void;
}

export const AbortDialog: React.SFC<IProps> = (props) => {

    return (
        <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Avbryt test?
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom>
                    Om du avbryter kan du senare återuppta testet.
                    </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleConfirm} color="primary">
                    Avbryt test
                 </Button>
            </DialogActions>
        </Dialog>
    );
}