import { Button, Checkbox, Grid } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from "redux";
import { Routes } from '../model/routes';
import { RootState } from '../redux';
import { getQuestionsByClassifications } from "../redux/modules/question";
import { TopBar } from './../components/TopBar';
import { CategoryDto } from './../model/typegen/categoryDto';

interface IProps extends RouteComponentProps {
}

interface IState {
    selectedClassifications: string[];
    showAbortDialog: boolean;
}

const mapStateToProps = (state: RootState) => ({
    categories: state.questions.categories,
    categoryQuestions: state.questions.categoryQuestions
});

const mapDispatchToProps = {
    getQuestionsByClassifications
};

type IPropsWithStyle = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    IProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        selectionItem: {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '0.5rem',
            height: '3.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.palette.secondary.main
        },
        contentContainer: {
            padding: '2rem',
            paddingTop: '0.5rem',
            justifyContent: 'center',
            maxHeight: '60vh',
            overflow: 'auto',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                maxHeight: '60vw',
            },
        },
        categoryContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        startButton: {
            width: '100%',
            marginTop: '0.5rem'
        },
        textContainer: {
            overflow: 'hidden',
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            lineClamp: 2
        },
        image: {
            maxHeight: '100%',
            maxWidth: '100%',
        },
        imageContainer: {
            height: '2rem',
            width: '2rem',
            paddingRight: '1rem',
            marginLeft: '0.3rem',
            textAlign: 'center'
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: 'auto'
        }
    });

class Categories extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            showAbortDialog: false,
            selectedClassifications: []
        }
    }

    componentDidUpdate(prevProps: IPropsWithStyle) {
        if (this.props.categoryQuestions !== prevProps.categoryQuestions) {
            this.props.history.push(Routes.CategoriesTest);
        }
    }

    cancelClick = () => {
        this.props.history.push(Routes.Home);
    }

    handleChange = (item: CategoryDto) => {
        this.setState(state => ({
            selectedClassifications: state.selectedClassifications.includes(item.identifier) ? state.selectedClassifications.filter(c => c !== item.identifier) : [...state.selectedClassifications, item.identifier]
        }));
    };

    fetchQuestions = () => {
        this.props.getQuestionsByClassifications(this.props.categoryQuestions, this.state.selectedClassifications);
    };

    public render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.cancelClick} />
                <div className={classes.categoryContent}>
                    <h1>Kategorier</h1>
                    <Grid container className={classes.contentContainer}>
                        <Grid item xs={12} sm={8} md={6} >
                            {this.props.categories?.map((item, key) =>
                                <div key={key} style={{ marginTop: '0.3rem' }}>
                                    <Grid item xs={12} className={classes.selectionItem}>
                                        <Grid item xs={2} >
                                            <Checkbox
                                                onChange={() => this.handleChange(item)}
                                                checked={this.state.selectedClassifications.includes(item.identifier)}
                                            />
                                        </Grid>
                                        <Grid item xs={10} className={classes.textContainer}>
                                            {item.description}
                                        </Grid>
                                        <div className={classes.imageContainer}>
                                            <img
                                                src={"data:image/png;base64, " + item?.imageBase64}
                                                alt="CategoryImage"
                                                className={classes.image}
                                            />
                                        </div>
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <Grid container className={classes.categoryContent}>
                    <Grid container className={`${classes.contentContainer}`}>
                        <Grid item xs={6} sm={4}>
                            <Button variant="contained" className={classes.startButton} color="secondary" disabled={this.state.selectedClassifications.length === 0} onClick={this.fetchQuestions}>
                                <b>Starta</b>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <footer className={classes.footer} ></footer>
            </div >
        );
    }
}
export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(Categories));