import { Grid } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GridItemDto } from '../model/typegen/gridItemDto';

interface IProps extends RouteComponentProps {
    onItemClick?: ((identifier: string) => void);
    items: GridItemDto[]
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        mainContainer: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
        },
        gridItem: {
            padding: '0.3rem'
        },
        itemContent: {
            height: '20%',
            padding: '0.5rem',
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '0.5rem',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexDirection: 'column',
            color: theme.palette.secondary.main
        },
        image: {
            maxHeight: '100%',
            maxWidth: '100%',
        },
        imageContainer: {
            height: '4.5rem',
            width: '4.5rem',
            paddingBottom: '0.5rem',
            textAlign: 'center',
            paddingTop: '0.5rem'
        },
        descriptionContainer: {
            overflow: 'inherit',
            textOverflow: 'ellipsis',
            // whiteSpace: 'nowrap',
            // wordWrap: 'break-word',
            width: '100%',
            textAlign: 'center',
            fontSize: '0.7rem',
            height: '3rem',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            '@media (min-width: 813px) ': {
                fontSize: '0.9rem',
            }
        }
    }),
);

const RoadSignGrid: React.FC<IProps> = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.mainContainer}>
                {props.items?.map((item, key) =>
                    <Grid key={key} item xs={6} md={2} className={classes.gridItem} style={props.onItemClick ? { cursor: 'pointer' } : {}}>
                        <div className={classes.itemContent} onClick={() => props.onItemClick && props.onItemClick(item.identifier)}>
                            <div className={classes.imageContainer}>
                                <img
                                    src={"data:image/png;base64, " + item.imageBase64}
                                    alt="SignImage"
                                    className={classes.image}
                                />
                            </div>
                            <div className={classes.descriptionContainer}>
                                {item.description}
                            </div>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div >
    );
}

export default withRouter(RoadSignGrid);