// TODO: We should move typedAction elsewhere since it's shared
import * as _ from "lodash";
import { AnyAction, Dispatch } from 'redux';
import { getChoiceQuestionList, initialLoad } from '../../api/questionController';
import { Routes } from '../../model/routes';
import { ChoiceQuestionDto } from '../../model/typegen/choiceQuestionDto';
import { InitialLoadDto } from '../../model/typegen/initialLoadDto';
import { QuestionTypeChildren } from '../../model/typegen/questionTypeChildren';
import { typedAction } from '../helpers';
import { CategoryDto } from './../../model/typegen/categoryDto';

export type ChoiceQuestionResponse = {
    choiceQuestion: ChoiceQuestionDto
    responseOptionId: number
    correctResponseOptionId: number
    arrayIndexQuestion: number
};

export class QuestionConstants {
    public static readonly signCurrentQuestionId: string = "signQuestions.currentQuestionId";
    public static readonly signQuestionResponses: string = "signQuestions.questionResponses";
    public static readonly assertionQuestionResponses: string = "assertionQuestions.questionResponses";
    public static readonly mixedQuestionResponses: string = "mixedQuestions.questionResponses";
    public static readonly categoryQuestionIds: string = "categoryQuestions.questionIds";

    public static readonly localStorage: string = "localStorage";
    public static readonly localStorageCookiePolicy: string = "cookiePolicy";

    public static readonly choiceQuestionIdsToGet = 30;
}

export type QuestionTypeState = {
    questionIds: number[];
    currentQuestionId: number;
    questionResponses: ChoiceQuestionResponse[];
    questionTypeRoutes: QuestionTypeRoutes;
    classifications: QuestionTypeChildren[]
}

export interface QuestionTypeRoutes {
    test: string
    result: string
    resultReview: string
    resultReviewQuestion: string
}

export type QuestionState = {
    signQuestions: QuestionTypeState;
    assertionQuestions: QuestionTypeState;
    mixedQuestions: QuestionTypeState;
    categoryQuestions: QuestionTypeState;
    loading: boolean;
    acceptCookiePolicy: boolean | string | null;
    categories: CategoryDto[]
};

export const initialState: QuestionState = {
    signQuestions: {
        questionIds: [],
        currentQuestionId: 0,
        questionResponses: [],
        questionTypeRoutes: {
            test: Routes.SignTest,
            result: Routes.SignTestResult,
            resultReview: Routes.SignTestResultReview,
            resultReviewQuestion: Routes.SignTestResultReviewQuestion,
        },
        classifications: [QuestionTypeChildren.sign]
    },
    assertionQuestions: {
        questionIds: [],
        currentQuestionId: 0,
        questionResponses: [],
        questionTypeRoutes: {
            test: Routes.AssertionTest,
            result: Routes.AssertionTestResult,
            resultReview: Routes.AssertionTestResultReview,
            resultReviewQuestion: Routes.AssertionTestResultReviewQuestion,
        },
        classifications: [QuestionTypeChildren.assertion]
    },
    mixedQuestions: {
        questionIds: [],
        currentQuestionId: 0,
        questionResponses: [],
        questionTypeRoutes: {
            test: Routes.MixedTest,
            result: Routes.MixedTestResult,
            resultReview: Routes.MixedTestResultReview,
            resultReviewQuestion: Routes.MixedTestResultReviewQuestion,
        },
        classifications: [QuestionTypeChildren.sign, QuestionTypeChildren.assertion]
    },
    categoryQuestions: {
        questionIds: [],
        currentQuestionId: 0,
        questionResponses: [],
        questionTypeRoutes: {
            test: Routes.CategoriesTest,
            result: Routes.CategoriesTestResult,
            resultReview: Routes.CategoriesTestResultReview,
            resultReviewQuestion: Routes.CategoriesTestResultReviewQuestion,
        },
        classifications: []
    },
    categories: [],
    loading: true,
    acceptCookiePolicy: localStorage.getItem(QuestionConstants.localStorageCookiePolicy)
        ? localStorage.getItem(QuestionConstants.localStorageCookiePolicy)
        : false
};

//const addSignChoiceQuestions = (questions: number[]) => {
//    return typedAction('question/ADD_SIGN_CHOICE_QUESTIONS', questions);
//};

export const addToChoiceQuestionResponse = (stateProperty: QuestionTypeState, choiceQuestion: ChoiceQuestionDto, responseOptionId: number, correctResponseOptionId: number, arrayIndexQuestion: number) => {
    return typedAction('question/ADD_TO_RESPONSE', { stateProperty, choiceQuestion, responseOptionId, correctResponseOptionId, arrayIndexQuestion });
};

//export const setCurrentQuestionId = (stateProperty: string, currentQuestionId: number) => {
//    return typedAction('question/SET_QUESTION_ID', { stateProperty, currentQuestionId });
//};

export const setAcceptCookiePolicy = (acceptCookiePolicy: boolean) => {
    return typedAction('question/SET_ACCEPT_COOKIE_POLICY', { acceptCookiePolicy });
};

export const setLoading = (loading: boolean) => {
    return typedAction('question/SET_LOADING', { loading });
};

const setInitialLoadData = (data: InitialLoadDto) => {
    return typedAction('question/SET_INITIAL_LOAD_DATA', { data });
};

const setQuestionsData = (stateProperty: QuestionTypeState, questionIds: number[]) => {
    return typedAction('question/ADD_QUESTIONS', { stateProperty, questionIds });
};

// Action creator returning a thunk!
export const setInitialLoad = () => {
    return (dispatch: Dispatch<AnyAction>) => {
        initialLoad(QuestionConstants.choiceQuestionIdsToGet).then(x => {
            dispatch(
                setInitialLoadData(x.data)
            );
        });
    };
};

export const getQuestionsByClassifications = (stateProperty: QuestionTypeState, classifications: string[]) => {
    return (dispatch: Dispatch<AnyAction>) => {
        getChoiceQuestionList(QuestionConstants.choiceQuestionIdsToGet, classifications).then(x => {
            dispatch(
                setQuestionsData(stateProperty, x.data)
            );
        });
    };
};

type QuestionAction = ReturnType<typeof addToChoiceQuestionResponse | typeof setLoading |
    typeof setInitialLoadData | typeof setQuestionsData | typeof setAcceptCookiePolicy>;

export function questionsReducer(
    state = initialState,
    action: QuestionAction
): QuestionState {

    //Used for triggering redux re-render
    let newState: QuestionState;
    switch (action.type) {
        //case 'question/ADD_SIGN_CHOICE_QUESTIONS':
        //    return {
        //        ...state,
        //        signQuestionIds: [...state.signQuestionIds, ...action.payload],
        //    };
        case 'question/SET_LOADING': {
            return {
                ...state,
                loading: action.payload.loading,
            };
        }

        case 'question/SET_ACCEPT_COOKIE_POLICY': {
            //Risky but users did not like that the cookie policy was reset when all questions were reset
            localStorage.setItem(QuestionConstants.localStorageCookiePolicy, action.payload.acceptCookiePolicy.toString());
            return {
                ...state,
                acceptCookiePolicy: action.payload.acceptCookiePolicy,
            };
        }

        case 'question/SET_INITIAL_LOAD_DATA': {
            return {
                ...state,
                signQuestions: {
                    ...state.signQuestions,
                    questionIds: action.payload.data.signQuestionIds,
                    currentQuestionId: action.payload.data.signQuestionIds[0],
                },
                assertionQuestions: {
                    ...state.assertionQuestions,
                    questionIds: action.payload.data.assertionQuestionIds,
                    currentQuestionId: action.payload.data.assertionQuestionIds[0],
                },
                mixedQuestions: {
                    ...state.mixedQuestions,
                    questionIds: action.payload.data.mixedQuestionIds,
                    currentQuestionId: action.payload.data.mixedQuestionIds[0],
                },
                categories: action.payload.data.categories,
                loading: false,
            };
        }

        case 'question/ADD_TO_RESPONSE':

            let choiceQuestion = _.cloneDeep(action.payload.choiceQuestion);
            choiceQuestion.imageBase64 = '';
            choiceQuestion.options?.forEach(item => item.imageBase64 = '');

            let choiceQuestionResponse: ChoiceQuestionResponse = {
                choiceQuestion: choiceQuestion,
                responseOptionId: action.payload.responseOptionId,
                correctResponseOptionId: action.payload.correctResponseOptionId,
                arrayIndexQuestion: action.payload.arrayIndexQuestion,
            };
            action.payload.stateProperty.questionResponses.push(choiceQuestionResponse);
            newState = _.cloneDeep(state);
            return {
                ...newState,
            };

        case 'question/ADD_QUESTIONS':
            action.payload.stateProperty.questionIds = action.payload.questionIds;
            action.payload.stateProperty.currentQuestionId = action.payload.questionIds[0];
            action.payload.stateProperty.questionResponses = [];
            newState = _.cloneDeep(state);
            return {
                ...newState,
            };

        //case 'question/SET_QUESTION_ID':
        //    _.set(state, action.payload.stateProperty, action.payload.currentQuestionId)
        //    return {
        //        ...state,
        //    };
        default:
            return state;
    }
}