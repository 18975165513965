export class Routes {
    public static readonly Home: string = "/";
    public static readonly SingleQuestionWithIdentifier: string = "/single-question/:identifier";
    public static readonly SignTest: string = "/sign-test";
    public static readonly SignTestResult: string = "/sign-test/result";
    public static readonly SignTestResultReview: string = "/sign-test/result/review";
    public static readonly SignTestResultReviewQuestion: string = "/sign-test/result/review/question";
    public static readonly SignTestResultReviewQuestionWithId: string = Routes.SignTestResultReviewQuestion + "/:id";
    public static readonly AssertionTest: string = "/assertion-test";
    public static readonly AssertionTestResult: string = "/assertion-test/result";
    public static readonly AssertionTestResultReview: string = "/assertion-test/result/review";
    public static readonly AssertionTestResultReviewQuestion: string = "/assertion-test/result/review/question";
    public static readonly AssertionTestResultReviewQuestionWithId: string = Routes.AssertionTestResultReviewQuestion + "/:id";
    public static readonly MixedTest: string = "/mixed-test";
    public static readonly MixedTestResult: string = "/mixed-test/result";
    public static readonly MixedTestResultReview: string = "/mixed-test/result/review";
    public static readonly MixedTestResultReviewQuestion: string = "/mixed-test/result/review/question";
    public static readonly MixedTestResultReviewQuestionWithId: string = Routes.MixedTestResultReviewQuestion + "/:id";
    public static readonly Categories: string = "/categories";
    public static readonly CategoriesTest: string = "/categories-test";
    public static readonly CategoriesTestResult: string = "/categories-test/result";
    public static readonly CategoriesTestResultReview: string = "/categories-test/result/review";
    public static readonly CategoriesTestResultReviewQuestion: string = "/categories-test/result/review/question";
    public static readonly CategoriesTestResultReviewQuestionWithId: string = Routes.CategoriesTestResultReviewQuestion + "/:id";
    public static readonly RoadSignList: string = "/road-sign-list";
    public static readonly RoadSignListCategory: string = "/road-sign-list/category";
    public static readonly RoadSignListCategoryWithId: string = Routes.RoadSignListCategory + "/:id";
    public static readonly CookiePolicy: string = "/cookie-policy";

}
