import { faArrowLeft, faArrowRight, faVolume } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, FormControlLabel, FormGroup, Grid, LinearProgress, Switch } from "@material-ui/core";
import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "redux";
import { correctChoiceQuestion, getChoiceQuestion } from "../api/questionController";
import { AbortDialog } from "../components/dialog/AbortDialog";
import { SignQuestion } from "../components/question/SignQuestion";
import { TopBar } from "../components/TopBar";
import { Routes } from "../model/routes";
import { ChoiceQuestionDto } from "../model/typegen/choiceQuestionDto";
import { QuestionTypeChildren } from "../model/typegen/questionTypeChildren";
import { RootState } from "../redux";
import { addToChoiceQuestionResponse, ChoiceQuestionResponse, QuestionTypeState } from "../redux/modules/question";
import { setNarration } from "../redux/modules/user";
import { AssertionQuestion } from "../components/question/AssertionQuestion";
import { populateQuestionResponseImages } from "../utils/questionHelper";

const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 8,
            backgroundColor: theme.palette.primary.light,
            borderRadius: 20,
        },
        bar: {
            borderRadius: 20,
            backgroundColor: theme.palette.primary.main,
        },
    })
)(LinearProgress);

const mapStateToProps = (state: RootState) => ({
    narration: state.user.narration,
});

const mapDispatchToProps = {
    addToChoiceQuestionResponse,
    setNarration,
};

interface IProps extends RouteComponentProps {
    questions: QuestionTypeState,
}

interface IState {
    question?: ChoiceQuestionDto;
    questionIds: number[];
    questionResponses: ChoiceQuestionResponse[];
    correctOptionId?: number;
    clickedOptionId?: number;
    progressInPercent: number;
    showAbortDialog: boolean;
}

type IPropsWithStyle = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    IProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column',
        },
        backAndForwardButtons: {
            marginTop: 'auto',
            display: 'flex',
            justifyContent: 'space-evenly',
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
        },
        progressBar: {
            marginTop: '1.5rem',
        },
        progressBarTextContainer: {
            display: 'flex'
        },
        progressBarText: {
            color: theme.palette.primary.main,
        },
        progressBarIdentifier: {
            color: theme.palette.primary.main,
            marginLeft: 'auto',
        },
        questionContainer: {
            height: '100%',
        },
        questionContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        narrationSwitch: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingRight: '1rem',
        },
        centerContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
    });

class QuestionTest extends React.PureComponent<IPropsWithStyle, IState> {

    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            question: undefined,
            correctOptionId: undefined,
            clickedOptionId: undefined,
            progressInPercent: 0,
            questionIds: [],
            questionResponses: [],
            showAbortDialog: false,
        };
    }

    async componentDidMount() {
        this.setQuestionData(this.props.questions);
    }

    setQuestionData = async (
        questionTypeState: QuestionTypeState
    ) => {
        let questionId = 0;
        let question: ChoiceQuestionDto;
        let questionResponse: ChoiceQuestionResponse | null = null;
        let correctOptionId: number | undefined = undefined;
        let clickedOptionId: number | undefined = undefined;
        let questionResponses = questionTypeState.questionResponses;
        let questionIds = questionTypeState.questionIds;

        questionResponses = _.cloneDeep(questionResponses);
        //TODO: Test functionality when localstorage is present
        if (questionResponses.length !== 0) {
            //Index starts at 0 and length 1
            questionResponse = questionResponses[questionResponses.length - 1];
            let arrayIndex = questionIds.findIndex(
                (x) => x === questionResponse?.choiceQuestion?.questionId
            );
            if (arrayIndex !== questionIds.length - 1) {
                //Load next question
                questionId = questionIds[arrayIndex + 1];
                questionResponse = null;
            } else {
                questionId = questionIds[arrayIndex];
            }
        } else {
            questionId = questionIds[0];
        }

        if (questionResponse) {
            correctOptionId = questionResponse.correctResponseOptionId;
            clickedOptionId = questionResponse.responseOptionId;
        }

        question = await (await getChoiceQuestion(questionId)).data;
        let progress = this.calculateTotalProgress(questionId, questionIds);

        this.setState(() => ({
            question: question,
            progressInPercent: progress,
            questionIds: questionIds,
            questionResponses: questionResponses,
            correctOptionId: correctOptionId,
            clickedOptionId: clickedOptionId,
        }));
    };

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setNarration(event.target.checked);
    };

    questionButtonOnClick = async (
        clickedOptionId: number
    ) => {
        if (this.state.clickedOptionId) {
            return;
        }
        const questionId = this.state.question?.questionId as number;
        const correctOptionId = await (await correctChoiceQuestion(questionId))
            .data;

        const index = this.getCurrentQuestionIndex(
            questionId,
            this.state.questionIds
        );

        this.props.addToChoiceQuestionResponse(
            this.props.questions,
            this.state.question as ChoiceQuestionDto,
            clickedOptionId,
            correctOptionId,
            index
        );
        this.setState((prevState) => ({
            correctOptionId: correctOptionId,
            clickedOptionId: clickedOptionId,
            questionResponses: [
                ...prevState.questionResponses,
                {
                    choiceQuestion: this.state.question as ChoiceQuestionDto,
                    correctResponseOptionId: correctOptionId,
                    responseOptionId: clickedOptionId,
                    arrayIndexQuestion: index,
                },
            ],
        }));
    };
    forwardButtonDisabled = (): boolean => {
        let questionResponse = this.state.questionResponses.find(
            (x) => x.choiceQuestion?.questionId === this.state.question?.questionId
        );
        let disabled = questionResponse === undefined;
        return disabled;
    };
    forwardButtonClick = () => {
        if (
            this.state.question?.questionId ===
            this.state.questionIds[this.state.questionIds.length - 1] &&
            this.state.questionResponses.length === this.state.questionIds.length
        ) {
            this.props.history.push(this.props.questions.questionTypeRoutes.result);
        } else {
            this.routeButtonClick(1);
        }
    };

    backButtonClick = () => {
        this.routeButtonClick(-1);
    };

    showDialogClick = () => {
        this.setState(() => ({
            showAbortDialog: true,
        }));
    };

    closeDialogClick = () => {
        this.setState(() => ({
            showAbortDialog: false,
        }));
    };

    confirmDialogClick = () => {
        this.props.history.push(Routes.Home);
    };

    routeButtonClick = async (number: number) => {
        let questionId = this.state.question?.questionId;
        let index = this.state.questionIds.findIndex((x) => x === questionId);
        let questionIdToGet = this.state.questionIds[index + number];
        let questionResponse = this.state.questionResponses.find(
            (x) => x.choiceQuestion?.questionId === questionIdToGet
        );
        let question: ChoiceQuestionDto;
        let correctOptionId: number | undefined = undefined;
        let clickedOptionId: number | undefined = undefined;
        let questionResult = await (await getChoiceQuestion(questionIdToGet)).data;

        if (questionResponse) {
            correctOptionId = questionResponse.correctResponseOptionId;
            clickedOptionId = questionResponse.responseOptionId;
            question = questionResponse.choiceQuestion;

            populateQuestionResponseImages(question, questionResult);
        }
        else {
            question = questionResult;
        }

        let progress = this.calculateTotalProgress(
            questionIdToGet,
            this.state.questionIds
        );

        this.setState(() => ({
            question: question,
            progressInPercent: progress,
            correctOptionId: correctOptionId,
            clickedOptionId: clickedOptionId,
        }));
    };
    getCurrentQuestionIndex = (
        questionId: number,
        questionIds: number[]
    ): number => {
        return questionIds.findIndex((x) => x === questionId);
    };

    calculateTotalProgress = (
        questionId: number,
        questionIds: number[]
    ): number => {
        return (
            ((this.getCurrentQuestionIndex(questionId, questionIds) + 1) /
                questionIds.length) *
            100
        );
    };

    public render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.showDialogClick} />
                <AbortDialog
                    open={this.state.showAbortDialog}
                    handleClose={this.closeDialogClick}
                    handleConfirm={this.confirmDialogClick}
                />
                <div className={classes.centerContainer} style={{ height: 'inherit' }}>
                    <Grid container item xs={12} sm={10} md={5} className={classes.centerContainer}>
                        <Grid item xs={10} >
                            <div className={classes.progressBarTextContainer}>
                                <span className={classes.progressBarIdentifier}>
                                    {this.state.question?.identifier}
                                </span>
                            </div>
                            <BorderLinearProgress
                                variant="determinate"
                                color="secondary"
                                value={this.state.progressInPercent}
                            />
                            <span className={classes.progressBarText}>
                                <small>
                                    Fråga &nbsp;
                                    {this.getCurrentQuestionIndex(
                                    this.state.question?.questionId as number,
                                    this.state.questionIds
                                ) + 1}
                     /{this.state.questionIds.length}
                                </small>
                            </span>
                        </Grid>
                        <Grid container item xs={12} className={classes.centerContainer}>
                            {
                                this.state?.question?.questionType === QuestionTypeChildren.sign ? (
                                    this.state.question ? (
                                        <SignQuestion
                                            question={this.state.question}
                                            correctOptionId={this.state.correctOptionId}
                                            clickedOptionId={this.state.clickedOptionId}
                                            onClick={this.questionButtonOnClick}
                                        />
                                    ) : (
                                            <CircularProgress color="primary" size="4rem" />
                                        )
                                ) : (
                                        <AssertionQuestion
                                            question={this.state.question}
                                            correctOptionId={this.state.correctOptionId}
                                            clickedOptionId={this.state.clickedOptionId}
                                            onClick={this.questionButtonOnClick}
                                        />
                                    )}
                        </Grid>
                        <Grid container item xs={10} className={`${classes.backAndForwardButtons}`}>
                            <Button
                                disabled={
                                    this.getCurrentQuestionIndex(
                                        this.state.question?.questionId as number,
                                        this.state.questionIds
                                    ) === 0
                                }
                                onClick={this.backButtonClick}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} size="3x" />
                            </Button>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={this.props.narration}
                                            onChange={this.handleChange}
                                            name="narration"
                                            color="primary"
                                        />
                                    }
                                    label={<FontAwesomeIcon icon={faVolume} size="2x" />}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                            <Button
                                disabled={this.forwardButtonDisabled()}
                                onClick={this.forwardButtonClick}
                            >
                                <FontAwesomeIcon icon={faArrowRight} size="3x" />
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <footer className={classes.footer}></footer>
            </div>
        );
    }
}

export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(QuestionTest));
