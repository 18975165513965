import * as React from 'react';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { TopBar } from '../components/TopBar';
import { RootState } from '../redux';
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Routes } from '../model/routes';
import { getQuestionsByClassifications } from "../redux/modules/question";
import { getMediaCategoryList } from '../api/mediaController';
import RoadSignGrid from '../components/RoadSignGrid';
import { GridItemDto } from '../model/typegen/gridItemDto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";

export interface IProps extends RouteComponentProps {
    id: string;
}

interface IState {
    items: GridItemDto[]
}

const mapStateToProps = (state: RootState) => ({
    categories: state.questions.categories,
    categoryQuestions: state.questions.categoryQuestions
});

const mapDispatchToProps = {
    getQuestionsByClassifications
};

type IPropsWithStyle = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    IProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        mainContainer: {
            padding: '0.5rem',
            marginTop: '1rem',
            maxHeight: '80%',
            overflow: 'auto'
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: 'auto'
        }
    });

class RoadSignListCategory extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            items: []
        }
    }
    async componentDidMount() {
        if (!this.props.id) return;

        let result = (await getMediaCategoryList(this.props.id)).data

        this.setState({
            items: result
        });
    }

    cancelClick = () => {
        this.props.history.push(Routes.RoadSignList);
    }

    public render() {
        const { classes } = this.props;

        let backIcon = <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '2.1rem' }} />

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.cancelClick} icon={backIcon} />
                <div className={classes.mainContainer}>
                    <RoadSignGrid items={this.state.items}></RoadSignGrid>
                </div>
                <footer className={classes.footer} ></footer>
            </div >
        );
    }
}

export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(RoadSignListCategory));