import { typedAction } from "../helpers";

const initialState: UserState = { username: null, narration: false };

export const login = (username: string) => {
    return typedAction('user/LOGIN', username);
};

export const setNarration = (narration: boolean) => {
    return typedAction('user/SET_NARRATION', narration);
};

export const logout = () => {
    return typedAction('user/LOGOUT');
};
type UserAction = ReturnType<typeof login | typeof logout | typeof setNarration>;

export function userReducer(
    state = initialState,
    action: UserAction
): UserState {
    switch (action.type) {
        case 'user/LOGIN':
            return { ...state, username: action.payload};
        case 'user/LOGOUT':
            return { username: null, narration: false };
        case 'user/SET_NARRATION':
            return { ...state, narration: action.payload };
        default:
            return state;
    }
}