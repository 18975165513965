import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#6CAEC6',
            light: '#B5D6E2'
        },
        secondary: {
            main: '#49464E',
            light: '#F0F0F1'
        }
    },
    typography: {
        fontFamily: [
            'Overpass Regular',
            'Overpass Bold'
        ].join(','),
    },
    overrides: {
        MuiButton: {
            root: {
                color: '#49464E',
            },
        },
    },
});