import * as sdk from 'microsoft-cognitiveservices-speech-sdk';
import { SpeechSynthesizer } from 'microsoft-cognitiveservices-speech-sdk';
import { getToken } from '../api/microsoftCognitiveServicesSpeechController';

export const textToSpeech = async (text: string, setInPogressState: React.Dispatch<React.SetStateAction<boolean>>) => {
    setInPogressState(true);

    let token = await (await getToken()).data;

    let speechConfig = sdk.SpeechConfig.fromAuthorizationToken(token, "northeurope");

    speechConfig.speechSynthesisLanguage = "sv-SE";
    speechConfig.speechSynthesisVoiceName = "sv-SE-SofieNeural";

    let synthesizer: SpeechSynthesizer | undefined = new sdk.SpeechSynthesizer(speechConfig);

    synthesizer.speakTextAsync(
        text,
        function (result) {
            if (result.reason === sdk.ResultReason.SynthesizingAudioCompleted) {
                setTimeout(() => { setInPogressState(false); }, 1000);
            } else if (result.reason === sdk.ResultReason.Canceled) {
                console.log(result.errorDetails);
            }
            synthesizer?.close();
            synthesizer = undefined;
        },
        function (err) {
            console.log(err);
            synthesizer?.close();
            synthesizer = undefined;

            setInPogressState(false);
        });
};