import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import QuestionTest from '../views/QuestionTest';
import { getChoiceQuestionByIdentifier } from '../api/questionController';
import { QuestionTypeState } from '../redux/modules/question';
import { CircularProgressFullScreen } from './CircularProgressFullScreen';

interface IProps extends RouteComponentProps {
    identifier: string,
}

type Props = IProps;

const UnconnectedSingleQuestion: React.FC<Props> = (props) => {

    const [questionTypeState, setQuestionTypeState] = useState<QuestionTypeState>();

    // Similar to componentDidMount
    useEffect(() => {

        async function getChoiceQuestion(identifier: string) {
            let response = await (await getChoiceQuestionByIdentifier(identifier)).data;

            let questionTypeState: QuestionTypeState = {
                questionIds: [response.questionId],
                currentQuestionId: response.questionId,
                questionResponses: [],
                questionTypeRoutes: {
                    test: '',
                    result: '',
                    resultReview: '',
                    resultReviewQuestion: '',
                },
                classifications: []
            }
            setQuestionTypeState(questionTypeState);
        }

        getChoiceQuestion(props.identifier)
    }, []);

    return (
        <>
            {questionTypeState ?
                <QuestionTest questions={questionTypeState as QuestionTypeState} />
                :
                <CircularProgressFullScreen />
            }
        </>
    );
}

export const SingleQuestion = withRouter(UnconnectedSingleQuestion)