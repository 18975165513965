import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from 'redux-thunk';
import App from './App';
import './index.css';
import { rootReducer } from './redux';
import { QuestionConstants } from './redux/modules/question';
import * as serviceWorker from './serviceWorker';

//Lock screen in portrait mode - Not used since it throws an exception in Chrome Desktop and won't work on Safari or Safari Mobile
//window.screen.orientation.lock("portrait");

ReactGA.initialize('UA-17127953-8');
ReactGA.pageview(window.location.pathname + window.location.search);

//Load data from localStorage
const preLoadedState: any = localStorage.getItem(QuestionConstants.localStorage)
    ? JSON.parse(localStorage.getItem(QuestionConstants.localStorage) as string)
    : {}

//Create a new store
const store = createStore(rootReducer, preLoadedState, composeWithDevTools(applyMiddleware(thunk)));

//Subscribe to store and save any changes to localStorage
store.subscribe(() => {
    localStorage.setItem(QuestionConstants.localStorage, JSON.stringify(store.getState()) as any);
})

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App />
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

