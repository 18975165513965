import { faVolume } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { ChoiceQuestionDto } from '../../model/typegen/choiceQuestionDto';
import { RootState } from '../../redux';
import { textToSpeech } from '../../services/microsoftCognitiveServicesSpeechService';
import { SignQuestionButton } from './SignQuestionButton';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%'
        },
        imageContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                height: '50vh'
            },
            '@media (max-width: 1024px) ': {
                height: '50vw'
            },
            '@media (min-width: 1024px) ': {
                height: '15vw'
            }
        },
        textContainer: {
            marginTop: '1rem',
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem'
        },
        navigationContainer: {
            maxHeight: '60vh',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                maxHeight: '60vw'
            },
            overflow: 'auto',
            padding: '0.3rem'
        },
        image: {
            maxHeight: '95%',
            maxWidth: '95%',
            marginBottom: '1rem'
        },
        narrationButton: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '1rem',
            cursor: 'pointer'
        }
    }),
);

const mapStateToProps = (state: RootState) => ({
    narration: state.user.narration
});

interface IProps {
    question?: ChoiceQuestionDto
    correctOptionId?: number
    clickedOptionId?: number
    onClick: (optionId: number) => void
}

type Props = ReturnType<typeof mapStateToProps> & IProps;

const UnconnectedSignQuestion: React.FC<Props> = (props) => {

    const [isSpeechInprogress, setIsSpeechInprogress] = useState(false);

    const callTextToSpeech = (text: string) => {
        if (isSpeechInprogress) return;

        textToSpeech(text, setIsSpeechInprogress);
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.imageContainer}>
                <img src={"data:image/png;base64, " + props.question?.imageBase64} alt="SignImage" className={classes.image} />
            </div>
            <div className={classes.navigationContainer}>
                {props.question?.options.map((item, key) =>
                    <div key={key} className={classes.textContainer}>
                        <Grid container>
                            <Grid item xs={props.narration ? 11 : 12}>
                                <SignQuestionButton key={key} optionDto={item}
                                    correctOptionId={props.correctOptionId}
                                    clickedOptionId={props.clickedOptionId}
                                    onClick={props.onClick} />
                            </Grid>
                            <Grid item xs={1} className={classes.narrationButton} zeroMinWidth={props.narration ? true : false} >
                                <div style={{ display: props.narration ? '' : 'none' }} onClick={() => callTextToSpeech(item.phoneticText)}>
                                    <FontAwesomeIcon icon={faVolume} size="2x" />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                )}
            </div>
        </div>
    );
};

export const SignQuestion = connect(mapStateToProps)(
    UnconnectedSignQuestion,
);
