import * as React from 'react';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { TopBar } from '../components/TopBar';
import { RootState } from '../redux';
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { QuestionTypeState, ChoiceQuestionResponse } from "../redux/modules/question";
import { SignQuestion } from '../components/question/SignQuestion';
import { AssertionQuestion } from '../components/question/AssertionQuestion';
import { QuestionTypeChildren } from '../model/typegen/questionTypeChildren';
import { Grid, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { setNarration } from "../redux/modules/user";
import { faVolume } from "@fortawesome/pro-solid-svg-icons";
import { getChoiceQuestion } from '../api/questionController';
import { populateQuestionResponseImages } from '../utils/questionHelper';


export interface IProps extends RouteComponentProps {
    id: string;
    questionTypeState: QuestionTypeState;
}

interface IState {
    questionResponse?: ChoiceQuestionResponse,
}

const mapStateToProps = (state: RootState) => ({
    narration: state.user.narration,
});

const mapDispatchToProps = {
    setNarration,
};

type IPropsWithStyle = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    IProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        mainContainer: {
            padding: '0.5rem',
            marginTop: '1rem',
            height: 'inherit'
        },
        centerContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: 'auto'
        }
    });

class Question extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            questionResponse: undefined
        }
    }

    async componentDidMount() {
        if (!this.props.id) return;

        let questionResponse = this.props.questionTypeState.questionResponses.find(x => x.choiceQuestion.questionId === Number(this.props.id));

        if (!questionResponse) return;

        let questionResult = await (await getChoiceQuestion(Number(this.props.id))).data;

        populateQuestionResponseImages(questionResponse.choiceQuestion, questionResult);

        this.setState({
            questionResponse: questionResponse
        });
    }

    cancelClick = () => {
        this.props.history.push(this.props.questionTypeState.questionTypeRoutes.resultReview);
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.setNarration(event.target.checked);
    };

    public render() {
        const { classes } = this.props;

        let backIcon = <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '2.1rem' }} />

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.cancelClick} icon={backIcon} />
                <Grid container className={`${classes.mainContainer} ${classes.centerContainer}`}>
                    <Grid item xs={12} lg={10} >
                        <div className={classes.centerContainer}>
                            {
                                this.state?.questionResponse?.choiceQuestion?.questionType === QuestionTypeChildren.sign ? (
                                    <SignQuestion
                                        question={this.state.questionResponse?.choiceQuestion}
                                        correctOptionId={this.state?.questionResponse?.correctResponseOptionId}
                                        clickedOptionId={this.state?.questionResponse?.responseOptionId}
                                        onClick={() => { }}
                                    />
                                ) : (
                                        <AssertionQuestion
                                            question={this.state?.questionResponse?.choiceQuestion}
                                            correctOptionId={this.state?.questionResponse?.correctResponseOptionId}
                                            clickedOptionId={this.state?.questionResponse?.responseOptionId}
                                            onClick={() => { }}
                                        />
                                    )}
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={10} className={classes.centerContainer}>
                        <FormGroup row>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={this.props.narration}
                                        onChange={this.handleChange}
                                        name="narration"
                                        color="primary"
                                    />
                                }
                                label={<FontAwesomeIcon icon={faVolume} size="2x" />}
                                labelPlacement="start"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <footer className={classes.footer} ></footer>
            </div >
        );
    }
}
export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(Question));
