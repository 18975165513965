import * as React from 'react';
import { createStyles, withStyles, Theme, WithStyles } from '@material-ui/core/styles';
import { TopBar } from '../components/TopBar';
import { RootState } from '../redux';
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Routes } from '../model/routes';
import { getQuestionsByClassifications } from "../redux/modules/question";
import RoadSignGrid from '../components/RoadSignGrid';

interface IProps extends RouteComponentProps {
}

interface IState {

}

const mapStateToProps = (state: RootState) => ({
    categories: state.questions.categories,
    categoryQuestions: state.questions.categoryQuestions
});

const mapDispatchToProps = {
    getQuestionsByClassifications
};

type IPropsWithStyle = ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    IProps &
    WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        mainContainer: {
            padding: '0.5rem',
            marginTop: '1rem',
            maxHeight: '86%',
            overflow: 'auto'
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: 'auto'
        }
    });

class RoadSignList extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);
        this.state = {
            selectedClassifications: []
        }
    }

    cancelClick = () => {
        this.props.history.push(Routes.Home);
    }

    navigateClassification = (identifier: string) => {
        this.props.history.push({
            pathname: Routes.RoadSignListCategory + `/${identifier}`
        })
    }

    public render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.cancelClick} />
                <div className={classes.mainContainer}>
                    <RoadSignGrid onItemClick={this.navigateClassification} items={this.props.categories}></RoadSignGrid>
                </div>
                <footer className={classes.footer} ></footer>
            </div >
        );
    }
}

export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(RoadSignList));