import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from 'redux';
import { Shared } from './components/Shared';
import { Routes } from './model/routes';
import { RootState } from './redux';
import { setInitialLoad } from "./redux/modules/question";
import { theme } from './theme';
import Categories from './views/Categories';
import Home from './views/Home';
import Result from './views/Result';
import ResultReview from "./views/ResultReview";
import RoadSignList from './views/RoadSignList'
import RoadSignListCategory from './views/RoadSignListCategory'
import QuestionTest from './views/QuestionTest';
import Question from "./views/Question";
import CookiePolicy from './views/CookiePolicy';
import { SingleQuestion } from './components/SingleQuestion';
import { CircularProgressFullScreen } from './components/CircularProgressFullScreen';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        }
    }),
);

const mapDispatchToProps = (dispatch: Dispatch) => {
    return bindActionCreators(
        {
            setInitialLoad,
        },
        dispatch
    );
};

const mapStateToProps = (state: RootState) => ({
    loading: state.questions.loading,
    signQuestions: state.questions.signQuestions,
    assertionQuestions: state.questions.assertionQuestions,
    mixedQuestions: state.questions.mixedQuestions,
    categoryQuestions: state.questions.categoryQuestions,
});

interface IProps {

}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & IProps;

const UnconnectedApp: React.FC<Props> = (props) => {
    const classes = useStyles();

    if (props.loading) {
        props.setInitialLoad();
    }

    return (
        //useStyles -> makeStyles will cause double rendering in development only https://github.com/mui-org/material-ui/issues/20313#issuecomment-605619371
        <ThemeProvider theme={theme}>
            {props.loading ?
                <CircularProgressFullScreen />
                :
                /*
                 Shared is used for global styling
                 */
                <Shared>
                    <Switch>
                        <Route exact path={Routes.Home} render={(routeProps) => <Home />} />
                        <Route path={Routes.SingleQuestionWithIdentifier} render={(routeProps) => <SingleQuestion identifier={routeProps.match.params.identifier ?? ""} />} />

                        <Route exact path={Routes.SignTest} render={(routeProps) => <QuestionTest questions={props.signQuestions} />} />
                        <Route exact path={Routes.SignTestResult} render={(routeProps) => <Result questions={props.signQuestions} />} />
                        <Route exact path={Routes.SignTestResultReview} render={(routeProps) => <ResultReview questions={props.signQuestions} />} />
                        <Route path={Routes.SignTestResultReviewQuestionWithId} render={(routeProps) => <Question id={routeProps.match.params.id ?? ""} questionTypeState={props.signQuestions} />} />

                        <Route exact path={Routes.AssertionTest} render={(routeProps) => <QuestionTest questions={props.assertionQuestions} />} />
                        <Route exact path={Routes.AssertionTestResult} render={(routeProps) => <Result questions={props.assertionQuestions} />} />
                        <Route exact path={Routes.AssertionTestResultReview} render={(routeProps) => <ResultReview questions={props.assertionQuestions} />} />
                        <Route path={Routes.AssertionTestResultReviewQuestionWithId} render={(routeProps) => <Question id={routeProps.match.params.id ?? ""} questionTypeState={props.assertionQuestions} />} />

                        <Route exact path={Routes.MixedTest} render={(routeProps) => <QuestionTest questions={props.mixedQuestions} />} />
                        <Route exact path={Routes.MixedTestResult} render={(routeProps) => <Result questions={props.mixedQuestions} />} />
                        <Route exact path={Routes.MixedTestResultReview} render={(routeProps) => <ResultReview questions={props.mixedQuestions} />} />
                        <Route path={Routes.MixedTestResultReviewQuestionWithId} render={(routeProps) => <Question id={routeProps.match.params.id ?? ""} questionTypeState={props.mixedQuestions} />} />

                        <Route exact path={Routes.Categories} render={(routeProps) => <Categories />} />
                        <Route exact path={Routes.CategoriesTest} render={(routeProps) => <QuestionTest questions={props.categoryQuestions} />} />
                        <Route exact path={Routes.CategoriesTestResult} render={(routeProps) => <Result questions={props.categoryQuestions} />} />
                        <Route exact path={Routes.CategoriesTestResultReview} render={(routeProps) => <ResultReview questions={props.categoryQuestions} />} />
                        <Route path={Routes.CategoriesTestResultReviewQuestionWithId} render={(routeProps) => <Question id={routeProps.match.params.id ?? ""} questionTypeState={props.categoryQuestions} />} />

                        <Route exact path={Routes.RoadSignList} render={(routeProps) => <RoadSignList />} />
                        <Route path={Routes.RoadSignListCategoryWithId} render={(routeProps) => <RoadSignListCategory id={routeProps.match.params.id ?? ""} />} />

                        <Route exact path={Routes.CookiePolicy} render={(routeProps) => <CookiePolicy />} />

                    </Switch>
                </Shared>
            }
        </ThemeProvider>
    );
}

const App = connect(mapStateToProps, mapDispatchToProps)(UnconnectedApp);

export default App;