import { CircularProgress, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
        },
        circularProgressContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'inherit'
        },
    }),
);

export const CircularProgressFullScreen: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.circularProgressContainer}>
            <CircularProgress color="primary" size="4rem" />
        </div>
    );
}