import { ChoiceQuestionDto } from "../model/typegen/choiceQuestionDto";
import { QuestionTypeChildren } from "../model/typegen/questionTypeChildren";

export function populateQuestionResponseImages(question: ChoiceQuestionDto, questionResult: ChoiceQuestionDto) {
    if (questionResult.questionType === QuestionTypeChildren.sign) {
        question.imageBase64 = questionResult.imageBase64;
    }
    if (questionResult.questionType === QuestionTypeChildren.assertion) {
        question.options.forEach(option => {
            let questionOption = questionResult.options.find(x => x.optionId === option.optionId);

            option.imageBase64 = questionOption?.imageBase64 ?? '';
        })
    }
}