import { fade, Grid } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from "redux";
import { TopBar } from '../components/TopBar';
import { QuestionTypeChildren } from '../model/typegen/questionTypeChildren';
import { QuestionTypeState } from "../redux/modules/question";
import { ChoiceQuestionDto } from '../model/typegen/choiceQuestionDto';
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getChoiceQuestion } from '../api/questionController';

interface IProps extends RouteComponentProps {
    questions: QuestionTypeState;
}

interface IState {
    choiceQuestions: ChoiceQuestionDto[];
}

type IPropsWithStyle = IProps & WithStyles<typeof styles>;

const styles = (theme: Theme) =>
    createStyles({
        root: {
            height: 'inherit',
            display: 'flex',
            flexDirection: 'column'
        },
        optionItem: {
            backgroundColor: theme.palette.secondary.light,
            borderRadius: '0.5rem',
            height: '4.5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            color: theme.palette.secondary.main,
            width: '100%',
            padding: '0.5rem',
            cursor: "pointer"
        },
        contentContainer: {
            padding: '2rem',
            paddingTop: '0.5rem',
            justifyContent: 'center',
            maxHeight: '75vh',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                maxHeight: '75vw'
            },
            overflow: 'auto'
        },
        mainContent: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        imageContainer: {
            display: 'flex',
            maxHeight: '4.5rem',
            maxWidth: '4.5rem'
        },
        image: {
            maxHeight: '3rem',
            maxWidth: '3rem',
        },
        footer: {
            height: '1.2rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: 'auto'
        },
        correctResponse: {
            backgroundColor: fade(theme.palette.success.light, 0.4),
        },
        incorrectResponse: {
            backgroundColor: fade(theme.palette.error.light, 0.4),
        },
        textContent: {
            width: '90%',
            overflow: 'hidden',
            display: '-webkit-box',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            lineClamp: 3
        }
    });

class ResultReview extends React.PureComponent<IPropsWithStyle, IState> {
    constructor(props: IPropsWithStyle) {
        super(props);

        this.state = {
            choiceQuestions: []
        };
    }

    cancelClick = () => {
        this.props.history.push(this.props.questions.questionTypeRoutes.result);
    }

    async componentDidMount() {
        if (this.props.questions.questionResponses.length !== this.props.questions.questionIds.length) {
            this.props.history.push(this.props.questions.questionTypeRoutes.test);
        }

        let questions: (ChoiceQuestionDto)[] = [];
        for (let index = 0; index < this.props.questions.questionResponses.length; index++) {
            let response = this.props.questions.questionResponses[index];

            if (response.choiceQuestion.questionType === QuestionTypeChildren.sign) {
                let question = await this.getQuestionImage(response.choiceQuestion.questionId);

                if (question) questions.push(question);
            }
            else {
                let question = await this.getQuestionResponseImage(response.choiceQuestion.questionId)

                if (question) questions.push(question);
            }

            if (index !== 0 && (index % 5 === 0 || index === this.props.questions.questionIds.length - 1)) {
                this.setState({ choiceQuestions: [...this.state.choiceQuestions, ...questions] });
                questions = [];
            }
        }

    }

    navigate = (questionId: number) => {
        this.props.history.push(this.props.questions.questionTypeRoutes.resultReviewQuestion + `/${questionId}`)
    }

    getQuestionImage = async (questionId: number): Promise<ChoiceQuestionDto | undefined> => {
        let question = await (await getChoiceQuestion(questionId)).data

        return question;
    }

    getQuestionResponseImage = async (questionId: number): Promise<ChoiceQuestionDto | undefined> => {
        let question = await (await getChoiceQuestion(questionId)).data
        return question;
    }

    public render() {
        const { classes } = this.props;

        let backIcon = <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: '2.1rem' }} />

        return (
            <div className={classes.root}>
                <TopBar handleClose={this.cancelClick} icon={backIcon} />
                <div className={classes.mainContent}>
                    <h1>Resultat</h1>
                    <Grid container className={classes.contentContainer}>
                        <Grid item xs={12} md={5}>
                            {this.props.questions.questionResponses?.map((item, key) =>
                                <div key={key} style={{ marginTop: '0.3rem' }}>
                                    <Grid item xs={12} className={`${classes.optionItem} ${item.correctResponseOptionId === item.responseOptionId ? classes.correctResponse : classes.incorrectResponse}`} onClick={() => this.navigate(item.choiceQuestion.questionId)}>
                                        {
                                            item.choiceQuestion.questionType === QuestionTypeChildren.sign ? (
                                                <>
                                                    <Grid item xs={10}>
                                                        <div className={classes.textContent}>
                                                            {item?.choiceQuestion.options.find(x => x.optionId === item.correctResponseOptionId)?.text}
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={2} className={classes.imageContainer}>
                                                        {this.state.choiceQuestions.find(x => x.questionId === item.choiceQuestion.questionId)?.imageBase64 ? (<img
                                                            src={"data:image/png;base64, " + this.state.choiceQuestions.find(x => x.questionId === item.choiceQuestion.questionId)?.imageBase64}
                                                            alt="SignImage"
                                                            className={classes.image}
                                                        />) : (<></>)}

                                                    </Grid>
                                                </>
                                            ) : (
                                                    <>
                                                        <Grid item xs={10}>
                                                            <div className={classes.textContent}>
                                                                {item?.choiceQuestion.text}
                                                            </div>
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.imageContainer}>
                                                            {this.state.choiceQuestions.find(x => x.questionId === item.choiceQuestion.questionId)?.options.find(x => x.optionId === item.correctResponseOptionId)?.imageBase64 ?
                                                                (<img
                                                                    src={"data:image/png;base64, " + this.state.choiceQuestions.find(x => x.questionId === item.choiceQuestion.questionId)?.options.find(x => x.optionId === item.correctResponseOptionId)?.imageBase64}
                                                                    alt="SignImage"
                                                                    className={classes.image}
                                                                />) : (<></>)}

                                                        </Grid>
                                                    </>
                                                )}
                                    </Grid>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </div>
                <footer className={classes.footer} ></footer>
            </div >
        );
    }
}
export default withRouter(compose<React.ComponentType<IProps>>(
    withStyles(styles)
)(ResultReview));