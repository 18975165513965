import { faVolume } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState } from 'react';
import { connect } from "react-redux";
import { ChoiceQuestionDto } from "../../model/typegen/choiceQuestionDto";
import { RootState } from "../../redux";
import { textToSpeech } from "../../services/microsoftCognitiveServicesSpeechService";
import { AssertionQuestionButton } from "./AssertionQuestionButton";

const useStyles = makeStyles(() =>
    createStyles({
        root: {},
        titleContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '1.5rem',
            textAlign: 'center',
            minHeight: '3rem'
        },
        centerContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        optionContainer: {
            width: '78vw',
            '@media screen and (max-width: 812px) and (orientation: landscape)': {
                width: '78vh'
            },
            maxWidth: '1100px',
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
        },
        narrationButton: {
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '1rem',
            cursor: 'pointer'
        }
    }),
);

const mapStateToProps = (state: RootState) => ({
    narration: state.user.narration,
});

interface IProps {
    question?: ChoiceQuestionDto;
    correctOptionId?: number;
    clickedOptionId?: number;
    onClick: (optionId: number) => void;
}

type Props = ReturnType<typeof mapStateToProps> & IProps;

const UnconnectedAssertionQuestion: React.FC<Props> = (props) => {
    const [isSpeechInprogress, setIsSpeechInprogress] = useState(false);

    const callTextToSpeech = (text: string) => {
        if (isSpeechInprogress) return;

        textToSpeech(text, setIsSpeechInprogress);
    };

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container >
                <Grid item xs={props.narration ? 11 : 12}>
                    <div className={classes.titleContainer}>{props.question?.text}
                        <div className={classes.narrationButton} >
                            <span style={{ visibility: props.narration ? 'visible' : 'hidden' }}>
                                <FontAwesomeIcon icon={faVolume} size="1x" onClick={() => { callTextToSpeech(props.question?.phoneticText as string) }} />
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div className={classes.centerContainer}>
                <div className={classes.optionContainer}>
                    {props.question?.options.map((item, key) => (
                        <div key={key}>
                            <Grid container>
                                <Grid item xs={4}>
                                    <AssertionQuestionButton
                                        key={key}
                                        optionDto={item}
                                        correctOptionId={props.correctOptionId}
                                        clickedOptionId={props.clickedOptionId}
                                        onClick={props.onClick}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const AssertionQuestion = connect(mapStateToProps)(
    UnconnectedAssertionQuestion,
);
